import React, { SyntheticEvent } from "react";

import { rem } from "@mantine/core";
import { useState } from "react";
import { useMove } from "@mantine/hooks";
import { Rnd } from "react-rnd";

interface ILoc {
  x: number;
  y: number;
}

const Square = ({ loc }: { loc: ILoc }) => {
  let offsetX: number;
  let offsetY: number;

  const [location, setLocation] = useState({ x: 0.2, y: 0.6 });
  const [adjustNodeLocation, setAdjustNodeLocation] = useState({
    x: 0.2,
    y: 0.6,
  });
  const [size, setSize] = useState({
    width: 200,
    height: 200,
    x: 10,
    y: 10,
  });
  //const { ref, active } = useMove(setLocation);

  return (
    <>
      <Rnd
        bounds="parent"
        onDragStop={(e, d) => {
          setSize({ width: size.width, height: size.height, x: d.x, y: d.y });
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
            setSize({
              width: parseInt(ref.style.width),
              height: parseInt(ref.style.height),
              ...position,
            });
          }}
        default={{
          x: 0,
          y: 0,
          width: 320,
          height: 200,
        }}
        style={{
          // position: "absolute",
          // left: `calc(${.2 * 100}% - ${rem(16)})`,
          // top: `calc(${.2 * 100}% - ${rem(16)})`,
          // width: rem(size),
          // height: rem(size),
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: `rgba(0,0,0,0.5)`,
        }}
      >
        {`x:${size.x}, y:${size.y}, h:${size.height}, w:${size.width}`}
      </Rnd>
    </>
  );
};

export default Square;
