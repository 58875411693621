import { Chip, Group } from "@mantine/core";

const PointsChip = ({
  onSkillChange,
  index,
  points,
  totalPoints,
}: {
  onSkillChange: Function;
  index: number;
  points: Array<number>;
  totalPoints: number;
}) => {
  const onChange = (newValue: any) => {
    if (newValue.length > 0) {
      const newNewValue = newValue.slice(newValue.length - 1);
      var currentPointValue = points[index];

      var newPointValue = 0;
      if (newNewValue[0] == "1pt") {
        newPointValue = 1;
      } else if (newNewValue[0] == "3pt") {
        newPointValue = 3;
      } else if (newNewValue[0] == "6pt") {
        newPointValue = 6;
      }

      if (totalPoints - currentPointValue + newPointValue > 20) {
        return;
      }

      onSkillChange(newNewValue, index);
    } else {
      onSkillChange(newValue, index);
    }
  };

  const pointValue = points[index];
  let value = "";
  if (pointValue == 1) {
    value = "1pt";
  } else if (pointValue == 3) {
    value = "3pt";
  } else if (pointValue == 6) {
    value = "6pt";
  }

  return (
    <Chip.Group multiple value={[value]} onChange={onChange}>
      <Group
        onClick={(e) => {
          e.stopPropagation();
        }}
        justify="center"
      >
        <Chip value="1pt">1pt</Chip>
        <Chip value="3pt">3pt</Chip>
        <Chip value="6pt">6pt</Chip>
      </Group>
    </Chip.Group>
  );
};

export default PointsChip;