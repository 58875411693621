import "@mantine/core/styles.css";

import {
  IconShare,
  IconCopy,
  IconClipboardCheck,
  IconCoffee,
} from "@tabler/icons-react";
import { useState, useEffect, useRef } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  AppShell,
  Burger,
  MantineProvider,
  SimpleGrid,
  Container,
  Group,
  Text,
  Center,
  Code,
  Space,
  Button,
  CopyButton,
  Flex,
  Tooltip,
  Image,
  TextInput,
  Box,
  rem,
} from "@mantine/core";
import { useSearchParams } from "react-router-dom";

import { attack, utility, tank } from "./Beastiary";
import SkillSection from "./components/SkillSection";
import Navigation from "./components/Navigation";
import bestiaryppng from "./bestiary.png";
import { db } from "./storage/db";
import classes from "./App.module.css";

export default function App() {
  const [opened, { toggle }] = useDisclosure();
  const [attackPoints, setAttackPoints] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [utilityPoints, setUtilityPoints] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [tankPoints, setTankPoints] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [scriptOutput, setScriptOutput] = useState("");
  const [shareParams, setShareParams] = useState("");
  const [bestiaryName, setBestiaryName] = useState("New Bestiary");

  let [searchParams, setSearchParams] = useSearchParams();

  const hasLoaded = useRef(false);

  const SCRIPT_RESPONSE = "waitforgump 1353229584\n";
  const SCRIPT_START =
    'if findtype "taming bestiary" backpack as book\n   dclick book\nwait 200\n';
  const SCRIPT_SELECT_ATTACK = "gumpresponse 3\n";
  const SCRIPT_SELECT_RESET = "gumpresponse 12\n";
  const SCRIPT_SELECT_OPTION = "gumpresponse 20";

  const SCRIPT_RESET_ATTACK_PAGE = `while not ingump 'Ambush' 1353229584
    gumpresponse 3
    pause 250
    waitforgump 1353229584 2000
    while not ingump '1/3' 1353229584
       gumpresponse 10 1353229584
       pause 250
       waitforgump 1353229584 2000
     endwhile
endwhile\n`;

  const SCRIPT_RESET_UTILITY_PAGE = `while not ingump 'Beast Sting' 1353229584
    gumpresponse 4
    pause 250
    waitforgump 1353229584 2000
    while not ingump '1/3' 1353229584
        gumpresponse 10 1353229584
        pause 250
        waitforgump 1353229584 2000
    endwhile
endwhile\n`;

  const SCRIPT_RESET_TANK_PAGE = `while not ingump 'Adrenaline' 1353229584
    gumpresponse 5
    pause 250
    waitforgump 1353229584 2000
    while not ingump '1/3' 1353229584
        gumpresponse 10 1353229584
        pause 250
        waitforgump 1353229584 2000
    endwhile
endwhile\n`;

  const SCRIPT_GOTO_PAGE_2 = `while not ingump '2/3' 1353229584
    gumpresponse 11 1353229584
    pause 250
    waitforgump 1353229584 2000
endwhile\n`;

  const SCRIPT_GOTO_PAGE_3 = `while not ingump '3/3' 1353229584
    gumpresponse 11 1353229584
    pause 250
    waitforgump 1353229584 2000
endwhile\n`;

  useEffect(() => {
    const attackTotalPoints = attackPoints.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    const utilityTotalPoints = utilityPoints.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    const tankTotalPoints = tankPoints.reduce(
      (partialSum, a) => partialSum + a,
      0
    );
    var script = "";
    if (
      attackTotalPoints > 0 ||
      utilityTotalPoints > 0 ||
      tankTotalPoints > 0
    ) {
      script = script.concat(SCRIPT_START);
      script = script.concat("\n");

      if (attackTotalPoints > 0) {
        script = script.concat(SCRIPT_RESET_ATTACK_PAGE);
        script = script.concat("\n");

        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat("\n");

        var pageTwo = false;
        var pageThree = false;

        for (let i = 0; i < attackPoints.length; i++) {
          if (attackPoints[i] > 0) {
            if (i > 3 && !pageTwo) {
              pageTwo = true;
              script = script.concat(SCRIPT_GOTO_PAGE_2);
              script = script.concat("\n");
            }

            if (i > 7 && !pageThree) {
              pageThree = true;
              script = script.concat(SCRIPT_GOTO_PAGE_3);
              script = script.concat("\n");
            }

            const select = `${SCRIPT_SELECT_OPTION}${i % 4}\n`;

            if (attackPoints[i] >= 1) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (attackPoints[i] >= 3) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (attackPoints[i] >= 6) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            script = script.concat("\n");
          }
        }
      }

      if (tankTotalPoints > 0) {
        script = script.concat(SCRIPT_RESET_TANK_PAGE);
        script = script.concat("\n");

        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat("\n");

        var pageTwo = false;
        var pageThree = false;

        for (let i = 0; i < tankPoints.length; i++) {
          if (tankPoints[i] > 0) {
            if (i > 3 && !pageTwo) {
              pageTwo = true;
              script = script.concat(SCRIPT_GOTO_PAGE_2);
              script = script.concat("\n");
            }

            if (i > 7 && !pageThree) {
              pageThree = true;
              script = script.concat(SCRIPT_GOTO_PAGE_3);
              script = script.concat("\n");
            }

            const select = `${SCRIPT_SELECT_OPTION}${i % 4}\n`;

            if (tankPoints[i] >= 1) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (tankPoints[i] >= 3) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (tankPoints[i] >= 6) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            script = script.concat("\n");
          }
        }
      }

      if (utilityTotalPoints > 0) {
        script = script.concat(SCRIPT_RESET_UTILITY_PAGE);
        script = script.concat("\n");

        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat(SCRIPT_SELECT_RESET);
        script = script.concat(SCRIPT_RESPONSE);
        script = script.concat("\n");

        var pageTwo = false;
        var pageThree = false;

        for (let i = 0; i < utilityPoints.length; i++) {
          if (utilityPoints[i] > 0) {
            if (i > 3 && !pageTwo) {
              pageTwo = true;
              script = script.concat(SCRIPT_GOTO_PAGE_2);
              script = script.concat("\n");
            }

            if (i > 7 && !pageThree) {
              pageThree = true;
              script = script.concat(SCRIPT_GOTO_PAGE_3);
              script = script.concat("\n");
            }

            const select = `${SCRIPT_SELECT_OPTION}${i % 4}\n`;

            if (utilityPoints[i] >= 1) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (utilityPoints[i] >= 3) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            if (utilityPoints[i] >= 6) {
              script = script.concat(select);
              script = script.concat(SCRIPT_RESPONSE);
            }

            script = script.concat("\n");
          }
        }
      }
    }
    setScriptOutput(script);
  }, [attackPoints, utilityPoints, tankPoints]);

  useEffect(() => {
    if (hasLoaded.current == true) {
      // const data = { attackPoints: attackPoints }
      // localStorage.setItem("outlandsbestiarytrianer", JSON.stringify(data));

      const newSearchParams = new URLSearchParams();
      newSearchParams.set(
        "points",
        JSON.stringify([attackPoints, utilityPoints, tankPoints])
      );
      setShareParams(newSearchParams.toString());
    }

    hasLoaded.current = true;
  }, [attackPoints, utilityPoints, tankPoints]);

  useEffect(() => {
    const urlPoints = searchParams.get("points");
    if (urlPoints != null) {
      const parsedPoints = JSON.parse(urlPoints);
      setAttackPoints(parsedPoints[0]);
      setUtilityPoints(parsedPoints[1]);
      setTankPoints(parsedPoints[2]);

      const newSearchParams = new URLSearchParams();
      setSearchParams(newSearchParams);
    } else {
      // const localStoreageData = localStorage.getItem("outlandsbestiarytrianer");
      // if (localStoreageData != null) {
      //   const savedData = JSON.parse(localStoreageData || "")
      //   setAttackPoints(savedData.attackPoints);
      // }
    }
  }, []);

  const resetCurrentSelections = () => {
    setAttackPoints([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setUtilityPoints([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setTankPoints([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    setScriptOutput("");
    setShareParams("");
  };

  const select = () => {
    window
      .getSelection()
      ?.selectAllChildren(document.getElementById("code-text")!);
  };

  const SHARE_ICON = <IconShare size={14} />;
  const COPY_ICON = <IconCopy size={24} />;
  const COPY_CHECK_ICON = <IconClipboardCheck size={24} />;

  return (
    <MantineProvider defaultColorScheme="dark">
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <div className={classes.header}>
            <Group gap="xs" wrap="nowrap" h="100%" px="md">
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
              <Image radius="md" src={bestiaryppng} />
              <Text size="xl">Outlands Bestiary Trainer</Text>
            </Group>
            <Group h="100%" px="md">
              <Tooltip label="Thank you!" position="left" offset={5}>
                <Button
                  component="a"
                  variant="subtle"
                  color="gray"
                  href="https://ko-fi.com/onebadbanana"
                  rightSection={
                    <IconCoffee style={{ width: rem(20), height: rem(20) }} />
                  }
                ><Text visibleFrom="xs">Buy me a coffee</Text></Button>
              </Tooltip>
            </Group>
          </div>
        </AppShell.Header>

        <AppShell.Navbar>
          <Navigation
            setAttackPoints={setAttackPoints}
            setUtilityPoints={setUtilityPoints}
            setTankPoints={setTankPoints}
          ></Navigation>
        </AppShell.Navbar>

        <AppShell.Main>
          <Box mb="xs" w={400}>
            <TextInput
              placeholder="Enter bestiary name"
              value={bestiaryName}
              onChange={(event) => setBestiaryName(event.currentTarget.value)}
            />
          </Box>
          <Container size="responsive">
            <SimpleGrid cols={{ base: 1, md: 1, xl: 3 }}>
              <SkillSection
                skills={attack}
                name="Attack"
                points={attackPoints}
                setPoints={setAttackPoints}
              />
              <SkillSection
                skills={utility}
                name="Utility"
                points={utilityPoints}
                setPoints={setUtilityPoints}
              />
              <SkillSection
                skills={tank}
                name="Tank"
                points={tankPoints}
                setPoints={setTankPoints}
              />
            </SimpleGrid>
            <Space h="xl" />
            <Center>
              <Button
                variant="light"
                color="red"
                onClick={resetCurrentSelections}
              >
                Reset
              </Button>
              <Space w="sm" />
              <Button
                onClick={async () => {
                  const id = await db.setups.add({
                    name: bestiaryName,
                    attack: attackPoints,
                    utility: utilityPoints,
                    tank: tankPoints,
                  });
                }}
              >
                Save
              </Button>
              <Space w="sm" />
              <CopyButton value={`${window.location.href}?${shareParams}`}>
                {({ copied, copy }) => (
                  <Button
                    color={copied ? "teal" : "blue"}
                    onClick={copy}
                    rightSection={SHARE_ICON}
                  >
                    {copied ? "URL Copied" : "Share"}
                  </Button>
                )}
              </CopyButton>
            </Center>
            <Space h="md" />
            <Container size="lg">
              <Group grow>
                <Text size="xl" inline>
                  Razor Script
                </Text>
                <Flex
                  gap="md"
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <CopyButton value={scriptOutput}>
                    {({ copied, copy }) => (
                      <Tooltip label="Copy script" position="left" offset={5}>
                        <Button color={copied ? "teal" : "blue"} onClick={copy}>
                          {copied ? COPY_CHECK_ICON : COPY_ICON}
                        </Button>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Group>
              <Space h="md" />
            </Container>
            <Container size="lg">
              <Center>
                <Code id="code-text" w="100%" onClick={select} block>
                  {scriptOutput}
                </Code>
              </Center>
            </Container>
          </Container>
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
}
