import "@mantine/core/styles.css";

import {
  IconShare,
  IconCopy,
  IconClipboardCheck,
  IconCoffee,
} from "@tabler/icons-react";
import { script_1, script_2 } from "./OrgScript";
import { useState, useEffect, useRef } from "react";
import { useDisclosure, useMove } from "@mantine/hooks";
import {
  AppShell,
  Burger,
  MantineProvider,
  SimpleGrid,
  Container,
  Group,
  Text,
  Center,
  Code,
  Space,
  Button,
  CopyButton,
  Flex,
  Tooltip,
  Image,
  TextInput,
  Box,
  FileButton,
  rem,
} from "@mantine/core";
import { useSearchParams } from "react-router-dom";

import { IconDatabaseImport } from "@tabler/icons-react";

import { attack, utility, tank } from "./Beastiary";
import SkillSection from "./components/SkillSection";
import Navigation from "./components/Navigation";
import bestiaryppng from "./sos-case-48.png";
import zones from "./zones.jpg";
import OutlandsMap from "./OutlandsMap";
import { db } from "./storage/db";
import classes from "./App.module.css";

const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
};

const scannerScript = `@clearignore

overhead "Select full SOS case"
setvar full_sos_case
wait 200
overhead "Select empty SOS case"
setvar empty_sos_case

sysmsg "…MIBify v1" 23
sysmsg "…MIBify:Start" 23
setvar! mibing 1
while not dead
    if insysmsg "The world will save in"
      while not insysmsg "World save complete."
        pause 1000
      endwhile
      clearsysmsg 
    endif
    
    while mibing = 1
        dclick full_sos_case
        wait 200
        while findtype 5357 backpack as mib
            getlabel 'mib' desc
        sysmsg "…SOS DESC:{{desc}}" 23
            @ignore mib
            wait 200
            menu empty_sos_case 0
            wait 200
            dclick full_sos_case
            wait 200
        endwhile
        
        setvar! mibing 0
        sysmsg "…MIBify:Stop" 23
    endwhile
endwhile`;

const ZONE_1_1 = [
  [0, 0],
  [1895, 0],
  [0, 782],
  [1895, 782],
];
const ZONE_1_2 = [
  [0, 5201],
  [3050, 5201],
  [0, 6135],
  [3050, 6135],
];
const ZONE_2_1 = [
  [1896, 0],
  [4731, 0],
  [1896, 782],
  [4731, 782],
];
const ZONE_2_2 = [
  [3051, 5201],
  [4731, 5201],
  [3051, 6135],
  [4731, 6135],
];
const ZONE_3_1 = [
  [0, 783],
  [1219, 783],
  [0, 2926],
  [1219, 2926],
];
const ZONE_3_2 = [
  [3501, 783],
  [4731, 783],
  [3501, 2926],
  [4731, 2926],
];
const ZONE_4 = [
  [1220, 783],
  [3500, 783],
  [1220, 1855],
  [3500, 1855],
];
const ZONE_5 = [
  [0, 2927],
  [3050, 2927],
  [0, 5200],
  [3050, 5200],
];
const ZONE_6 = [
  [3051, 2927],
  [4731, 2927],
  [3051, 5200],
  [4731, 5200],
];
const ZONE_7 = [
  [1220, 1856],
  [3500, 1856],
  [1220, 2926],
  [3500, 2926],
];

const ZONES = [
  ZONE_1_1,
  ZONE_1_2,
  ZONE_2_1,
  ZONE_2_2,
  ZONE_3_1,
  ZONE_3_2,
  ZONE_4,
  ZONE_5,
  ZONE_6,
  ZONE_7,
];

const getZone = (cord: String) => {
  const cords = cord.split(",");
  const location = [parseInt(cords[0]), parseInt(cords[1])];
  var zoneNumber = 0;
  // var zone:any;
  console.log(`Location: ${location[0]}, ${location[1]}`);
  for (var zone of ZONES) {
    if (location[0] >= zone[0][0] && location[1] >= zone[0][1]) {
      console.log(`Zone: ${zoneNumber} - lower pass`);
      if (location[0] <= zone[3][0] && location[1] <= zone[3][1]) {
        return zoneNumber;
      }
    }

    zoneNumber++;
  }

  return -1;
};

const generateScript = (soses: String[][]) => {
  const pushList = [];

  console.log(soses);

  for (var sos of soses[0]) {
    pushList.push(`pushlist region_1_list '${sos}'\n`);
  }

  for (var sos of soses[1]) {
    pushList.push(`pushlist region_1_list '${sos}'\n`);
  }

  for (var sos of soses[2]) {
    pushList.push(`pushlist region_2_list '${sos}'\n`);
  }

  for (var sos of soses[3]) {
    pushList.push(`pushlist region_2_list '${sos}'\n`);
  }

  for (var sos of soses[4]) {
    pushList.push(`pushlist region_3_list '${sos}'\n`);
  }

  for (var sos of soses[5]) {
    pushList.push(`pushlist region_3_list '${sos}'\n`);
  }

  for (var sos of soses[6]) {
    pushList.push(`pushlist region_4_list '${sos}'\n`);
  }

  for (var sos of soses[7]) {
    pushList.push(`pushlist region_5_list '${sos}'\n`);
  }

  for (var sos of soses[8]) {
    pushList.push(`pushlist region_6_list '${sos}'\n`);
  }

  for (var sos of soses[9]) {
    pushList.push(`pushlist region_7_list '${sos}'\n`);
  }

  return script_1 + pushList.join("") + script_2;
};

export default function Sosify() {
  const [opened, { toggle }] = useDisclosure();

  const [orgScript, setOrgScript] = useState(String);

  const SHARE_ICON = <IconShare size={14} />;
  const COPY_ICON = <IconCopy size={24} />;
  const COPY_CHECK_ICON = <IconClipboardCheck size={24} />;
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    resetRef.current?.();
  };

  const [locations, setLocations] = useState<Array<String>>([]);

  const [value, setValue] = useState({ x: 0.2, y: 0.6 });
  const { ref, active } = useMove(setValue);

  return (
    <MantineProvider defaultColorScheme="dark">
      <AppShell
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <div className={classes.header}>
            <Group gap="xs" wrap="nowrap" h="100%" px="md">
              <Burger opened={opened} onClick={toggle} hiddenFrom="sm" />
              <Image radius="md" src={bestiaryppng} />
              <Text size="xl">SOSify - A SOS Organizer</Text>
            </Group>
            <Group h="100%" px="md">
              <Tooltip label="Thank you!" position="left" offset={5}>
                <Button
                  component="a"
                  variant="subtle"
                  color="gray"
                  href="https://ko-fi.com/onebadbanana"
                  rightSection={
                    <IconCoffee style={{ width: rem(20), height: rem(20) }} />
                  }
                >
                  <Text visibleFrom="xs">Buy me a coffee</Text>
                </Button>
              </Tooltip>
            </Group>
          </div>
        </AppShell.Header>

        <AppShell.Navbar></AppShell.Navbar>

        <AppShell.Main>
          <p>
            Step 1: Scan your MIBs to generate log file with the below script.
            Tip: Log infresh to reduce the log file. Logout to be able to copy
            the log file.
          </p>
          <Container size="lg">
            <Container size="lg">
              <Group grow>
                <Text size="xl" inline>
                  Scanner Script
                </Text>
                <Flex
                  gap="md"
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <CopyButton value={scannerScript}>
                    {({ copied, copy }) => (
                      <Tooltip label="Copy script" position="left" offset={5}>
                        <Button color={copied ? "teal" : "blue"} onClick={copy}>
                          {copied ? COPY_CHECK_ICON : COPY_ICON}
                        </Button>
                      </Tooltip>
                    )}
                  </CopyButton>
                </Flex>
              </Group>
              <Space h="md" />
            </Container>
            <Center>
              <Code id="code-text" w="100%" block>
                {scannerScript}
              </Code>
            </Center>
          </Container>
          <p>Step 2: Upload your log file to generate the sorting script.</p>
          <FileButton
            resetRef={resetRef}
            onChange={(file: File) => {
              readFile(file).then(
                async (data: any) => {
                  // console.log(data);

                  const regex = /located at (\d+, \d+)/g;
                  const found = [...data.matchAll(regex)];

                  const soses = found.map((sos) => {
                    return sos[1];
                  });
                  console.log(soses);

                  setLocations(soses);

                  const sosArray: String[][] = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                  ];

                  console.log("Getting Zones");
                  for (var sos of soses) {
                    const idx = getZone(sos);
                    console.log(idx);
                    if (idx >= 0) {
                      sosArray[idx].push(sos);
                    }
                  }

                  console.log(sosArray);

                  setOrgScript(generateScript(sosArray));

                  clearFile();
                },
                (error) => {
                  console.log(error);
                }
              );
            }}
            accept=".txt"
          >
            {(props) => (
              <Button
                {...props}
                size="xs"
                rightSection={<IconDatabaseImport size={16} />}
                variant="default"
              >
                Upload Journal
              </Button>
            )}
          </FileButton>
          <p>
            Step 3: Run the SOS organizer script. You will need 7 SOS Cases.
            Each SOS case will corraspond to the zones detailed below.
          </p>
          <Flex
            gap="sm"
            justify="center"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <OutlandsMap soses={locations}/>
            <Container size="lg" w="40%">
              <Container size="lg">
                <Group grow>
                  <Text size="xl" inline>
                    Organizer Script
                  </Text>
                  <Flex
                    gap="md"
                    justify="flex-end"
                    align="center"
                    direction="row"
                    wrap="wrap"
                  >
                    <CopyButton value={orgScript}>
                      {({ copied, copy }) => (
                        <Tooltip label="Copy script" position="left" offset={5}>
                          <Button
                            color={copied ? "teal" : "blue"}
                            onClick={copy}
                          >
                            {copied ? COPY_CHECK_ICON : COPY_ICON}
                          </Button>
                        </Tooltip>
                      )}
                    </CopyButton>
                  </Flex>
                </Group>
                <Space h="md" />
              </Container>
              <Center>
                <Code id="code-text" w="100%" block>
                  {orgScript}
                </Code>
              </Center>
            </Container>
          </Flex>
        </AppShell.Main>
      </AppShell>
    </MantineProvider>
  );
}
