
import {
    Accordion,
    Text,
    Center,
  } from "@mantine/core";

import PointsChip from "./PointsChip";

const SkillSection = ({
    skills,
    name,
    points,
    setPoints,
  }: {
    skills: Array<{ name: string; description: string }>;
    name: string;
    points: Array<number>;
    setPoints: Function;
  }) => {
    const onSkillChange = (newValue: String, index: number) => {
      const newPoints = Array.from(points);
  
      var newPointValue = 0;
      if (newValue == "1pt") {
        newPointValue = 1;
      } else if (newValue == "3pt") {
        newPointValue = 3;
      } else if (newValue == "6pt") {
        newPointValue = 6;
      }
  
      newPoints[index] = newPointValue;
      setPoints(newPoints);
    };
  
    const totalPoints = points.reduce((partialSum, a) => partialSum + a, 0);
  
    const items = skills.map((item, index) => (
      <Accordion.Item key={item.name} value={item.name}>
        <Accordion.Control
          icon={
            <PointsChip
              onSkillChange={onSkillChange}
              points={points}
              totalPoints={totalPoints}
              index={index}
            />
          }
        >
          {item.name}
        </Accordion.Control>
        <Accordion.Panel >
          {item.description.split("\n").map((text, index) => (
            <div key={index}>
              {text}
              <br />
            </div>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    ));
  
    return (
      <div>
        <Center>
          <Text size="xl">{name}</Text>
        </Center>
        <Center>
          <Text size="32pt">{`${totalPoints} / 20`}</Text>
        </Center>
        <Accordion multiple>{items}</Accordion>
      </div>
    );
  };

  export default SkillSection;