export const script_1 =
`@clearignore

overhead 'Select FROM SOS Case'
setvar from_sos_case
wait 200
overhead 'Select region 1 SOS Case'
setvar region_1_bag
wait 200
overhead 'Select region 2 SOS Case'
setvar region_2_bag
wait 200
overhead 'Select region 3 SOS Case'
setvar region_3_bag
wait 200
overhead 'Select region 4 SOS Case'
setvar region_4_bag
wait 200
overhead 'Select region 5 SOS Case'
setvar region_5_bag
wait 200
overhead 'Select region 6 SOS Case'
setvar region_6_bag
wait 200
overhead 'Select region 7 SOS Case'
setvar region_7_bag
wait 200

createlist 'region_1_list'
clearlist 'region_1_list'

createlist 'region_2_list'
clearlist 'region_2_list'

createlist 'region_3_list'
clearlist 'region_3_list'

createlist 'region_4_list'
clearlist 'region_4_list'

createlist 'region_5_list'
clearlist 'region_5_list'

createlist 'region_6_list'
clearlist 'region_6_list'

createlist 'region_7_list'
clearlist 'region_7_list'

`

export const script_2 =
`
setvar! next_sos 0

while not dead
    if insysmsg "The world will save in"
      while not insysmsg "World save complete."
        pause 1000
      endwhile
      clearsysmsg 
    endif
    
    dclick from_sos_case
    wait 200
    
    if insysmsg "There are no SOSes"
        stop
    endif
        
    while findtype 5357 backpack as sos
        getlabel 'sos' sos_label

        if next_sos = 0
            foreach 'item' in 'region_1_list'
                if item in sos_label
                    menu region_1_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif
        
        if next_sos = 0
            foreach 'item' in 'region_2_list'
                if item in sos_label
                    menu region_2_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif
        
        if next_sos = 0
            foreach 'item' in 'region_3_list'
                if item in sos_label
                    menu region_3_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif
        
        if next_sos = 0
            foreach 'item' in 'region_4_list'
                if item in sos_label
                    menu region_4_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif
        
        if next_sos = 0
            foreach 'item' in 'region_5_list'
                if item in sos_label
                    menu region_5_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif

        if next_sos = 0
            foreach 'item' in 'region_6_list'
                if item in sos_label
                    menu region_6_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif
        
        if next_sos = 0
            foreach 'item' in 'region_7_list'
                if item in sos_label
                    menu region_7_bag 0
                    wait 200
                  
                    setvar! next_sos 1
                    break
                endif
            endfor
        endif

        if next_sos = 1
            @ignore sos
            setvar! next_sos 0
            wait 200
            continue
        else
            overhead 'ERROR: Unidentified MIB found!! Remove from inventory and restart script'
            wait 2000
            stop
            stop
            stop
        endif

        @ignore sos
    endwhile
endwhile
`
