export const SUN_WYRMMS = {
  id: "sun_wrymms",
  desc: "Sun Wrymm and Wrymmling",
  attack: [0, 0, 6, 0, 0, 0, 6, 0, 6, 1, 0, 1],
  utility: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  tank: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
};

export const SNOWDRIFTS = {
    id: "snowdrifts",
    desc: "Snowdrifts",
    attack: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    utility: [0, 6, 1, 1, 0, 6, 0, 0, 0, 0, 6, 0],
    tank: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  export const MNATICORE_ANTKEG = {
    id: "manticore_antkeg",
    desc: "Manticore and Antkeg",
    attack: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    utility: [3, 6, 1, 1, 0, 1, 0, 0, 1, 1, 6, 0],
    tank: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };

  export const CAVEBATS = {
    id: "cavebats",
    desc: "Cave Bats (dexxer)",
    attack: [0, 0, 3, 1, 6, 0, 3, 0, 0, 0, 1, 6],
    utility: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    tank: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  };


const METAS = [SUN_WYRMMS, SNOWDRIFTS, MNATICORE_ANTKEG, CAVEBATS]

export default METAS;
