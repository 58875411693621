import { createRoot } from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import Sosify from "./Sosify";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/sosify",
    element: <Sosify />,
  },
]);

const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript
root.render(<RouterProvider router={router} />);
