import { useState, useRef } from "react";
import {
  Box,
  NavLink,
  Text,
  Flex,
  Tooltip,
  Button,
  Group,
  FileButton,
  rem,
} from "@mantine/core";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../storage/db";
import METAS from "../metas/Metas";
import {
  IconTrash,
  IconDatabaseImport,
  IconFileExport,
} from "@tabler/icons-react";
import { saveAs } from "file-saver";

const readFile = (file: File) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsText(file);
  });
};

const Navigation = ({
  setAttackPoints,
  setUtilityPoints,
  setTankPoints,
}: {
  setAttackPoints: Function;
  setUtilityPoints: Function;
  setTankPoints: Function;
}) => {
  const [active, setActive] = useState("");
  const setups = useLiveQuery(() => db.setups.toArray());
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    resetRef.current?.();
  };

  const navSection = (
    <Box>
      <NavLink label="Metas" childrenOffset={0}>
        {METAS.map((setup) => (
          <NavLink
            active={setup.id === active}
            label={
              <Text size="sm" ml="md">
                {setup.desc}
              </Text>
            }
            onClick={() => {
              setActive(setup.id);
              setAttackPoints(setup.attack);
              setUtilityPoints(setup.utility);
              setTankPoints(setup.tank);
            }}
          />
        ))}
      </NavLink>
      <NavLink
        key={setups?.length}
        defaultOpened={true}
        label="My Bestiaries"
        childrenOffset={0}
      >
        {setups?.map((setup) => (
          <NavLink
            key={setup.id}
            active={`${setup.id}` === active}
            label={
              <Group grow>
                <Text size="sm" ml="md">
                  {setup.name}
                </Text>
                <Flex
                  gap="md"
                  justify="flex-end"
                  align="center"
                  direction="row"
                  wrap="wrap"
                >
                  <Tooltip label="Delete bestiary" position="right" offset={5}>
                    <Button
                      size="compact-xs"
                      variant="light"
                      color="red"
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        event.stopPropagation();
                        db.setups.where("id").equals(setup.id!).delete();
                      }}
                    >
                      <IconTrash style={{ width: rem(16), height: rem(16) }} />
                    </Button>
                  </Tooltip>
                </Flex>
              </Group>
            }
            onClick={() => {
              setActive(`${setup.id}`);
              setAttackPoints(setup.attack);
              setUtilityPoints(setup.utility);
              setTankPoints(setup.tank);
            }}
          />
        ))}
      </NavLink>
      <NavLink label="Settings" childrenOffset={0}>
        <Group justify="center">
          <Button
            size="xs"
            variant="default"
            rightSection={<IconFileExport size={16} />}
            onClick={() => {
              var blob = new Blob(
                [
                  JSON.stringify(
                    setups?.map((setup) => {
                      const { id, ...rest } = setup;
                      return rest;
                    })
                  ),
                ],
                {
                  type: "text/plain;charset=utf-8",
                }
              );
              saveAs(blob, "outlands_bestiary_trainer.json");
            }}
          >
            Export
          </Button>
          <FileButton
            resetRef={resetRef}
            onChange={(file: File) => {
              readFile(file).then(
                async (data) => {
                  console.log(data);
                  const importedSetups = JSON.parse(data as any);
                  const id = await db.setups.bulkAdd(importedSetups);
                  clearFile();
                },
                (error) => {
                  console.log(error);
                }
              );
            }}
            accept=".json"
          >
            {(props) => (
              <Button
                {...props}
                size="xs"
                rightSection={<IconDatabaseImport size={16} />}
                variant="default"
              >
                Import
              </Button>
            )}
          </FileButton>
        </Group>
      </NavLink>
    </Box>
  );

  return navSection;
};

export default Navigation;
