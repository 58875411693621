export const attack = [
  {
    name: "Ambush",
    description:
      "Damage Dealt increased by (10%, 20%, 30%) and Damage Resistance by (5% / 10% / 15%) for 10 seconds after exiting Stealth (including on Backstab)\nDamage Dealt increased by (4% / 8% / 12%) if creature does not have Stealth",
  },
  {
    name: "Feral",
    description:
      "While Frenzied or Enraged, creature's first non-backstab Melee Attack every 15 seconds has its Damage increased by (80% / 160% / 240%)\nDamage Dealt increased by(4 %, 8 %, 12 %) if creature does not have either of those abilities",
  },
  {
    name: "Cornered",
    description:
      "Damage Dealt increased by (10%, 20%, 30%) if follower has taken damage from another creature within the last 10 seconds\nDamage Dealt increased by (4% / 8% / 12%) if follower has not taken damage from another creature within the last 10 seconds",
  },
  {
    name: "Feed",
    description:
      "If creature has a Bleed effect on target, Melee Attacks have a(10 % / 20% / 30 %) chance to restore 10 % of Max Health(up to once every 15 seconds)\nDamage Dealt increased by(4 %, 8 %, 12 %) if target cannot inflict Bleed effects",
  },
  {
    name: "Hunting Party",
    description:
      "Damage Dealt increased by(10 % / 20% / 30 %) if creature and Tamer are both within 1 tile of target\nDamage Dealt increased by(4 % / 8% / 12 %) if creature or Tamer are more than 1 tile from target",
  },
  {
    name: "Immolate",
    description:
      "Flamestrike and Spellburn abilities now have Damage increased by(50 % / 100% / 150 %)\nDamage Dealt increased by(4 % / 8% / 12 %) if creature does not have either of those abilities",
  },
  {
    name: "Lethality",
    description:
      "Melee Attacks and Spellcasts have a(10 % / 20% / 30 %) chance to inflict + 100 % Damage, otherwise will inflict(4 % / 8% / 12 %) additional Damage",
  },
  {
    name: "Menagerie",
    description:
      "Damage Dealt increased by((2 % / 4% / 6 %) * Different Types of Followers Controlled By Player)\nHealing Amounts increased by((2 % / 4% / 6 %) * Different Types of Followers Controlled By Player)",
  },
  {
    name: "Mutation",
    description:
      "Reduces cooldown length of Cooldown Abilities by(2 / 4 / 6) seconds\nDamage Dealt increased by(4 % / 8% / 12 %) if creature does not have a Cooldown Ability",
  },
  {
    name: "Pelt",
    description:
      "Damage Dealt increased by(8 % / 16% / 24 %) if target is 4 tiles away or more\nDamage Dealt increased by(4 % / 8% / 12 %) if target is within 3 tiles",
  },
  {
    name: "Primal",
    description:
      "Chance to trigger Passive Abilities increased by(33 % / 66% / 100 %) of normal\nDamage Dealt increased by(4 % / 8% / 12 %) if creature does not have a Passive Ability",
  },
  {
    name: "Swarm",
    description:
      "Damage Dealt increased by((2 % / 4% / 6 %) * Total Followers Controlled By Player)\nHealing Amounts increased by((2 % / 4% / 6 %) * Total Followers Controlled By Player)",
  },
];

export const utility = [
  {
    name: "Beast Sting",
    description:
      "If any of your creatures has Poison or Disease active on target, increases Damage of Melee Attacks by (10% / 20% / 30%) if creature can inflict Poison or Disease effects\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Poison or Disease effects",
  },
  {
    name: "Breaker",
    description:
      "Melee Attacks and Spellcasts ignore (22% / 44% / 66%) of target's Armor and Magic Resist",
  },
  {
    name: "Carrion",
    description:
      "If creature has Disease active on target, Melee Attacks have a (10% / 20% / 30%) chance to restore 12.5% of Max Health (up to once every 15 seconds)\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Disease effects",
  },
  {
    name: "Contagion",
    description:
      "Increases the Damage of Disease effects by (33% / 66% / 100%)\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Disease effects",
  },
  {
    name: "Debilitate",
    description:
      "If any of your creatures has Poison or Disease active on target, the target will receive a stackable (1% / 2% / 3%) Damage Penalty against the Tamer and their Followers for each Control Slot worth of Utility-class creatures that can inflict Poison or Disease effects\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Poison or Disease effects",
  },
  {
    name: "Frostbite",
    description:
      "Damage of Chill Abilities increased by (15% / 30% / 45%) and amount of Chill effect applied increased by (33% / 66% / 100%) of normal\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Chill effects",
  },
  {
    name: "Metabolism",
    description:
      "Successful Melee Attacks will restore (0.66% / 1.33% / 2.0%) of Max Health\nDamage Dealt increased by (4% / 8% / 12%) if already at Max Health",
  },
  {
    name: "Opportunity",
    description:
      "Melee and Spell Damage increased by (20%, 40%, 60%) if creature has applied an Entangle or Hinder effect to the target within last 10 seconds\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Entangle or Hinder effects",
  },
  {
    name: "Punishment",
    description:
      "When creature's Pierce or Hex effects expire on a target, will inflict (DamageMax * (0.5 / 1.0 / 1.5)) Damage onto them which ignores Armor\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Pierce or Hex effects",
  },
  {
    name: "Scour",
    description:
      "If creature has a Weaken or Cripple effect on target, their Tamer will receive a ((1.5% / 3% / 4.5%) * Control Slots) Damage Bonus (stackable) against the target\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict these effects",
  },
  {
    name: "Swoop",
    description:
      "The next Melee Attack or Spellcast within 10 seconds after using a Cooldown Ability will have its Damage increased by (66% / 133% / 200%)\nDamage Dealt increased by (4% / 8% / 12%) if creature does not have a Cooldown Ability",
  },
  {
    name: "Toxic",
    description:
      "If any of your creatures has Poison or Disease active on target, their Tamer will receive a stackable (1% / 2% / 3%) Damage Bonus against the target for each Control Slot worth of Utility-class creatures that can inflict Poison or Disease effects\nDamage Dealt increased by (4% / 8% / 12%) if creature cannot inflict Poison or Disease effects",
  },
];

export const tank = [
  {
    name: "Adrenaline",
    description:
      "Creature has a (15% / 30% / 45%) chance on any Damage Taken that would kill it, to instead take 1 Damage and receive 50% Damage Resistance for the next 5 seconds",
  },
  {
    name: "Aggression",
    description:
      "Melee Damage increased by (5% / 10% / 15%) for each target within 2 tiles that is Aggroed against them (up to 3 targets)\nDamage Dealt increased by (4% / 8% / 12%) if no targets within 2 tiles are Aggroed against them",
  },
  {
    name: "Behemoth",
    description:
      "Melee and Spell Damage increased by (10% / 20% / 30%) and Damage Resistance by (2% / 4% / 6%) if creature takes 3 or more Control Slots\nDamage Resistance increased by (1.33% / 2.66% / 4%) if creature takes 1 or 2 Control Slots",
  },
  {
    name: "Critical Care",
    description:
      "Veterinary Resurrection chances increased by (33% / 66% / 100%) of normal and creature will Resurrect with an additional (10% / 20% / 30%) of Max Health [will not work if PvP Flagged]",
  },
  {
    name: "Deflection",
    description:
      "Grants Damage Resistance of ((1.5% / 3% / 4.5%) * Control Slots) to non-Tank Followers and ((0.75% / 1.5% / 2.25%) * Control Slots) to the Tamer if they are within 2 tiles",
  },
  {
    name: "Guardian",
    description:
      "((3% / 6% / 9%) * Control Slots) of Damage Taken by non-Tank Class Followers and ((1.5% / 3% / 4.5%) * Control Slots) of Damage Taken by Tamer within 2 tiles will be redirected amongst Tank Class followers (divided equally) as a direct health loss",
  },
  {
    name: "Hearty",
    description:
      "Damage Dealt increased by (10% / 20% / 30%) when above 75% Health\nDamage Dealt increased by (4% / 8% / 12%) when below 75% Health",
  },
  {
    name: "Mending",
    description:
      "Healing Amounts increased by (12% / 24% / 36%) and Curing chances by (20% / 40% / 60%)\nDamage Dealt increased by (4% / 8% / 12%) when at Max Health and not Poisoned",
  },
  {
    name: "Stalwart",
    description:
      "Damage Resistance increased by (3% / 6% / 9%) and has a (25% / 50% / 75%) chance to ignore any non-movement Debuff effects (such as Cripple, Pierce, Hex, etc)",
  },
  {
    name: "Survival",
    description: "Damage Resistance increased by (4% / 8% / 12%)",
  },
  {
    name: "Tolerance",
    description:
      "Has a (11% / 22% / 33%) chance to ignore any Bleed, Disease, or Poison tick\nDamage Resistance increased by (1.33% / 2.66% / 4.0%) while not under the effect of Bleed, Disease, or Poison",
  },
  {
    name: "Trample",
    description:
      "Melee Attacks inflict an additional (DamageMax * (8% / 16% / 24%)) to 2 random targets within 1 tile",
  },
];
