import Dexie, { Table } from "dexie";

export interface Setup {
  id?: number;
  name: string;
  attack: Array<number>;
  utility: Array<number>;
  tank: Array<number>;
}

export class Bestiaries extends Dexie {
  setups!: Table<Setup>;

  constructor() {
    super("bestiaries");
    this.version(1).stores({
      setups: "++id",
    });
  }
}

export const db = new Bestiaries();
