import React from "react";

import {
  Button,
  Container,
  CopyButton,
  Flex,
  Group,
  Image,
  rem,
  Space,
  Tooltip,
  Text,
  Checkbox,
} from "@mantine/core";
import { useState } from "react";
import { Rnd } from "react-rnd";

import Square from "./Square";

import zoneMap from "./zones-clear.jpg";

const getLocX = (x: number) => {
  return (x / 4734) * 100;
};
const getLocY = (y: number) => {
  return (y / 6144) * 100;
};

const ZONE_1_1 = [
  [0, 0],
  [1895, 0],
  [0, 782],
  [1895, 782],
];
const ZONE_1_2 = [
  [0, 5201],
  [3050, 5201],
  [0, 6144],
  [3050, 6144],
];
const ZONE_2_1 = [
  [1896, 0],
  [4731, 0],
  [1896, 782],
  [4731, 782],
];
const ZONE_2_2 = [
  [3051, 5201],
  [4731, 5201],
  [3051, 6144],
  [4731, 6144],
];
const ZONE_3_1 = [
  [0, 783],
  [1219, 783],
  [0, 2926],
  [1219, 2926],
];
const ZONE_3_2 = [
  [3501, 783],
  [4731, 783],
  [3501, 2926],
  [4731, 2926],
];
const ZONE_4 = [
  [1220, 783],
  [3500, 783],
  [1220, 1855],
  [3500, 1855],
];
const ZONE_5 = [
  [0, 2927],
  [3050, 2927],
  [0, 5200],
  [3050, 5200],
];
const ZONE_6 = [
  [3051, 2927],
  [4731, 2927],
  [3051, 5200],
  [4731, 5200],
];
const ZONE_7 = [
  [1220, 1856],
  [3500, 1856],
  [1220, 2926],
  [3500, 2926],
];

const standardZones = [
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_1_1[0][0] / 4734) * 800,
      y: (ZONE_1_1[0][1] / 6144) * 1039,
      width: ((ZONE_1_1[3][0] - ZONE_1_1[0][0]) / 4734) * 800,
      height: ((ZONE_1_1[3][1] - ZONE_1_1[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(196,223,155,0.5)`,
      color: "black",
    }}
  >
    {`Zone 1`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_1_2[0][0] / 4734) * 800,
      y: (ZONE_1_2[0][1] / 6144) * 1039,
      width: ((ZONE_1_2[3][0] - ZONE_1_2[0][0]) / 4734) * 800,
      height: ((ZONE_1_2[3][1] - ZONE_1_2[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(196,223,155,0.5)`,
      color: "black",
    }}
  >
    {`Zone 1`}
  </Rnd>,

  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_2_1[0][0] / 4734) * 800,
      y: (ZONE_2_1[0][1] / 6144) * 1039,
      width: ((ZONE_2_1[3][0] - ZONE_2_1[0][0]) / 4734) * 800,
      height: ((ZONE_2_1[3][1] - ZONE_2_1[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(249,173,129,0.5)`,
      color: "black",
    }}
  >
    {`Zone 2`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_2_2[0][0] / 4734) * 800,
      y: (ZONE_2_2[0][1] / 6144) * 1039,
      width: ((ZONE_2_2[3][0] - ZONE_2_2[0][0]) / 4734) * 800,
      height: ((ZONE_2_2[3][1] - ZONE_2_2[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(249,173,129,0.5)`,
      color: "black",
    }}
  >
    {`Zone 2`}
  </Rnd>,

  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_3_1[0][0] / 4734) * 800,
      y: (ZONE_3_1[0][1] / 6144) * 1039,
      width: ((ZONE_3_1[3][0] - ZONE_3_1[0][0]) / 4734) * 800,
      height: ((ZONE_3_1[3][1] - ZONE_3_1[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(255,247,153,0.5)`,
      color: "black",
    }}
  >
    {`Zone 3`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_3_2[0][0] / 4734) * 800,
      y: (ZONE_3_2[0][1] / 6144) * 1039,
      width: ((ZONE_3_2[3][0] - ZONE_3_2[0][0]) / 4734) * 800,
      height: ((ZONE_3_2[3][1] - ZONE_3_2[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(255,247,153,0.5)`,
      color: "black",
    }}
  >
    {`Zone 3`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_4[0][0] / 4734) * 800,
      y: (ZONE_4[0][1] / 6144) * 1039,
      width: ((ZONE_4[3][0] - ZONE_4[0][0]) / 4734) * 800,
      height: ((ZONE_4[3][1] - ZONE_4[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(245,152,157,0.5)`,
      color: "black",
    }}
  >
    {`Zone 4`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_5[0][0] / 4734) * 800,
      y: (ZONE_5[0][1] / 6144) * 1039,
      width: ((ZONE_5[3][0] - ZONE_5[0][0]) / 4734) * 800,
      height: ((ZONE_5[3][1] - ZONE_5[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(151,134,190,0.5)`,
      color: "black",
    }}
  >
    {`Zone 5`}
  </Rnd>,
  <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_6[0][0] / 4734) * 800,
      y: (ZONE_6[0][1] / 6144) * 1039,
      width: ((ZONE_6[3][0] - ZONE_6[0][0]) / 4734) * 800,
      height: ((ZONE_6[3][1] - ZONE_6[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(109,207,246,0.5)`,
      color: "black",
    }}
  >
    {`Zone 6`}
  </Rnd>,
    <Rnd
    bounds="parent"
    enableResizing={false}
    disableDragging={true}
    default={{
      x: (ZONE_7[0][0] / 4734) * 800,
      y: (ZONE_7[0][1] / 6144) * 1039,
      width: ((ZONE_7[3][0] - ZONE_7[0][0]) / 4734) * 800,
      height: ((ZONE_7[3][1] - ZONE_7[0][1]) / 6144) * 1039,
    }}
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: `rgba(245,66,76,0.5)`,
      color: "black",
    }}
  >
    {`Zone 7`}
  </Rnd>,
];

const OutlandsMap = ({ soses }: { soses: Array<String> }) => {
  const [useStandard, setUseStandard] = useState(true);
  const [value, setValue] = useState({ x: 0.1, y: 0.1 });
  // const { ref, active } = useMove(setValue);
  const [zones, setZones] = useState(standardZones);

  return (
    <Container>
      <Container>
        <Group grow>
          <Text size="xl" inline>
            Regions
          </Text>
          <Flex
            gap="md"
            justify="flex-end"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Checkbox
              label="Standard regions"
              checked={useStandard}
              onChange={(event) => setUseStandard(event.currentTarget.checked)}
            />
            <Button
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                let newZone = <Square loc={value} />;
                setZones([...zones, newZone]);
                event.preventDefault();
              }}
              variant="filled"
              disabled={useStandard}
            >
              + Region
            </Button>
          </Flex>
        </Group>
        <Space h="md" />
      </Container>
      <div
        // ref={ref}
        style={{ width: rem(800), height: rem(1039), position: "relative" }}
      >
        <Image radius="md" src={zoneMap} />
        {zones}
        {soses.map((sos: String) => {
          const cords = sos.split(",");
          const location = [parseInt(cords[0]), parseInt(cords[1])];

          return (
            <div
              style={{
                position: "absolute",
                left: `calc(${getLocX(location[0])}% - ${rem(4)})`,
                top: `calc(${getLocY(location[1])}% - ${rem(4)})`,
                width: rem(4),
                height: rem(4),
                borderRadius: rem(16),
                backgroundColor: `rgba(255,0,0,1)`,
              }}
            />
          );
        })}
      </div>
    </Container>
  );
};

export default OutlandsMap;
